import { Component, Input, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import * as rootReducer from '../../rootReducer';

class model{
    htmlString: string;
}

@Component({
    selector: 'html-view',
    templateUrl: './component.html',
    styleUrls: ['./component.css']
})

export class HtmlViewComponent implements OnInit {
    public m: model;
    @Input() data: string;
    constructor(private store: Store<rootReducer.State>) {
    }
    ngOnInit() {
        this.init_model();
        this.sub_store();
    }
    init_model() {
        this.m = new model();
    }
    sub_store() {}
}